import React from 'react';
import './locate-button.scss';
import classNames from 'classnames';

function LocateButton({ loading, className, onClick, showMessage, message, onCloseClick }) {
  const locateButtonClasses = classNames(`locate-button-container ${className}`, {
    'locate-button--loading': loading,
    'locate-button--show-message': showMessage
  });

  return (
    <div className={locateButtonClasses}>
      <button type="button" className="locate-button" onClick={onClick} aria-label="Locate Me">
        <svg className="locate-button__icon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.5 62.5">
          <path className="locate-button__crosshair" d="M62,30.75H58.22a26.28,26.28,0,0,0-25-25V2a1.25,1.25,0,0,0-2.5,0V5.78a26.28,26.28,0,0,0-25,25H2a1.25,1.25,0,0,0,0,2.5H5.78a26.28,26.28,0,0,0,25,25V62a1.25,1.25,0,0,0,2.5,0V58.22a26.28,26.28,0,0,0,25-25H62a1.25,1.25,0,0,0,0-2.5Zm-6.28,2.5A23.78,23.78,0,0,1,33.25,55.72c-.41,0-.83,0-1.25,0s-.84,0-1.25,0A23.78,23.78,0,0,1,8.28,33.25c0-.41,0-.83,0-1.25s0-.84,0-1.25A23.78,23.78,0,0,1,30.75,8.28c.41,0,.83,0,1.25,0s.84,0,1.25,0A23.78,23.78,0,0,1,55.72,30.75c0,.41,0,.83,0,1.25S55.74,32.84,55.72,33.25Z" transform="translate(-0.75 -0.75)" />
          <circle className="locate-button__center" cx="31.25" cy="31.25" r="16" />
        </svg>
      </button>
      <div className="locate-button-message">
        <div className="close" onClick={onCloseClick}></div>
        <p>{message}</p>
      </div>
    </div>
  );
}

export default LocateButton;