import React from 'react';
import PropTypes from 'prop-types';

/**
 * sizes
 * xs = 480
 * s = 600
 * m = 800
 * l = 1040
 * xl = 1600
 */

/*
  <ResponsiveImage
    src={}
    sources={{
      xs: ""
      s: ""
      m: ""
      l: ""
      xl: ""
    breakpoints={{
      xs: ""
      s: ""
      m: ""
      l: ""
      xl: ""
    }}
    }}
  />

  $bp-sm: 640px;
  $bp-md: 768px;
  $bp-lg: 1024px;
  $bp-xl: 1280px;
*/

function ResponsiveImage({ src, sources, sizes, alt = '', className = '', ...props }) {

  const sourcesString = `
    ${sources['xs']} 480w,
    ${sources['s']} 600w,
    ${sources['m']} 800w,
    ${sources['l']} 1040w,
    ${sources['xl']} 1600w,
  `;

  return (
    <img
      className={className}
      src={src}
      srcSet={sourcesString}
      sizes={sizes}
      alt={alt}
      {...props}
    />
  );
}

ResponsiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  sources: PropTypes.object.isRequired,
  sizes: PropTypes.string.isRequired,
  alt: PropTypes.string
};

export default ResponsiveImage;