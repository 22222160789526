import React from 'react';
import PropTypes from 'prop-types';
import './show-more.scss';
import useTranslations from '../../hooks/useTranslations';
import classNames from 'classnames';

function ShowMoreButton({ className, onClick, active = false }) {
  const {showMore, showLess} = useTranslations();

  const showMoreButtonClasses = classNames(`show-more ${className}`, {
    'show-more--active': active,
  });

  return (
    <button className={showMoreButtonClasses} type="button" onClick={onClick}>
      <div className="show-more-left">{active ? showLess : showMore}</div>
      <div className="show-more-right">
        <svg width="100%" viewBox="0 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g  transform="translate(-104.000000, -8.000000)" fill="#DFD7D0" fillRule="nonzero">
              <polygon  transform="translate(108.000000, 12.000000) rotate(-270.000000) translate(-108.000000, -12.000000) " points="104 12.4573805 104 11.5592516 110.316845 11.5592516 107.447465 8.64864865 108.072637 8 112 12.008316 108.072637 16 107.447465 15.3679834 110.316845 12.4573805"></polygon>
            </g>
          </g>
        </svg>
      </div>
    </button>
  );
}

ShowMoreButton.propTypes = {
  onClick: PropTypes.func
};

export default ShowMoreButton;