import { useEffect, useRef, useState } from 'react';
import { debounce } from '../utils';

const isClient = typeof window === 'object';

let isScrolling;

function useWindowScroll() {
  const frame = useRef(0);
  const [state, setState] = useState({
    x: isClient ? window.pageXOffset : 0,
    y: isClient ? window.pageYOffset : 0,
    scrolling: false,
  });
 
  useEffect(() => {
    function handler() {
      window.clearTimeout(isScrolling);
      cancelAnimationFrame(frame.current);
      frame.current = requestAnimationFrame(() => {
        setState({
          x: window.pageXOffset,
          y: window.pageYOffset,
          scrolling: true
        });
      });

      isScrolling = setTimeout(() => {
        setState({
          x: window.pageXOffset,
          y: window.pageYOffset,
          scrolling: false
        })
      }, 66);
    }

    window.addEventListener('scroll', handler, {
      capture: false,
      passive: false,
    });

    return () => {
      cancelAnimationFrame(frame.current);
      window.removeEventListener('scroll', handler);
    };
  }, []);

  return state;
}

export default useWindowScroll;


