import React, { useContext, useReducer, useEffect, useRef, createRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Store } from '../../Store';
// import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import MapMarker from '../MapMarker';
import Location from '../Location';
import './map-container.scss';
import useWindowScroll from '../../hooks/useWindowScroll';
import useWindowSize from '../../hooks/useWindowSize';
import useTranslations from '../../hooks/useTranslations';
import classNames from 'classnames';
import LocateButton from '../LocateButton';
import pentagonIcon from '../../images/icons/pentagon-brand.svg';
import { detectIE } from '../../utils';

let mapboxgl;
let ReactMapboxGl = {};
let Marker;
let isIE = false;
let isMapboxSupported = false;

if (typeof window !== `undefined`) {
  mapboxgl = require('mapbox-gl');
  ReactMapboxGl = require('react-mapbox-gl');
  Marker = ReactMapboxGl.Marker;
  isIE = detectIE();
  isMapboxSupported = mapboxgl.supported();
} else {
  ReactMapboxGl.Map = () => {
    return <div />
  }

  ReactMapboxGl.Marker = () => {
    return <div />
  }

  Marker = ReactMapboxGl.Marker;
}

// actions
const SET_MAP_LOADED = 'SET_MAP_LOADED';
const UPDATE_ACTIVE_PLACE = 'UPDATE_ACTIVE_PLACE';
const UPDATE_PLACES = 'UPDATE_PLACES';
const SET_USER_LOCATION = 'SET_USER_LOCATION';
const SET_LOCATE_LOADING = 'SET_LOCATE_LOADING';
const SHOW_LOCATE_MESSAGE = 'SHOW_LOCATE_MESSAGE';
const SET_INITIAL_LOCATION_LOADED = 'SET_INITIAL_LOCATION_LOADED';

// action functions
function setMapLoaded(bool) {
  return { type: SET_MAP_LOADED, mapLoaded: bool };
}

function setActivePlace(placeId) {
  return { type: UPDATE_ACTIVE_PLACE, activePlaceId: placeId };
}

function updatePlaces(places, allPlaceIds, bounds) {
  return {
    type: UPDATE_PLACES,
    places,
    allPlaceIds,
    bounds
  }
}

function setUserLocation(lng, lat) {
  return {
    type: SET_USER_LOCATION,
    userLocation: [lng, lat]
  }
}

function setLocateLoading(isLoading) {
  return {
    type: SET_LOCATE_LOADING,
    locateLoading: isLoading
  }
}

function setShowLocateMessage(showMessage, message) {
  return {
    type: SHOW_LOCATE_MESSAGE,
    showLocateMessage: showMessage,
    locateMessage: message
  }
}

function setInitialLocationLoaded(bool) {
  return {
    type: SET_INITIAL_LOCATION_LOADED,
    initalLocationLoaded: bool
  }
}

// reducer
function reducer(state, action) {
  // console.log(action);
  switch (action.type) {
    case SET_MAP_LOADED:
      return {
        ...state,
        mapLoaded: action.mapLoaded
      }
    case UPDATE_ACTIVE_PLACE:
      return {
        ...state,
        activePlaceId: action.activePlaceId
      }
    case UPDATE_PLACES:
      return {
        ...state,
        places: action.places,
        allPlaceIds: action.allPlaceIds,
        bounds: action.bounds,
        activePlaceId: 'all'
      }
    case SET_USER_LOCATION:
      return {
        ...state,
        userLocation: action.userLocation,
        showLocateMessage: false
      }
    case SET_LOCATE_LOADING:
      return {
        ...state,
        locateLoading: action.locateLoading
      }
    case SHOW_LOCATE_MESSAGE:
      return {
        ...state,
        showLocateMessage: action.showLocateMessage,
        locateMessage: action.locateMessage,
        userLocation: null,
      }
    case SET_INITIAL_LOCATION_LOADED:
      return {
        ...state,
        initalLocationLoaded: action.initalLocationLoaded
      }
    default:
      return state;
  }
}

function getBounds(markers) {
  const lngs = markers.map(marker => marker.longitude);
  const lats = markers.map(marker => marker.latitude);
  const maxLat = Math.max(...lats);
  const minLat = Math.min(...lats);
  const maxLng = Math.max(...lngs);
  const minLng = Math.min(...lngs);
  const southWest = [minLng, minLat];
  const northEast = [maxLng, maxLat];

  const southEast = [minLng, maxLat];
  const northWest = [maxLng, minLat];

  return {
    NE: northEast,
    SE: southEast,
    SW: southWest,
    NW: northWest
  };
}

// https://stackoverflow.com/questions/10939408/determine-if-lat-lng-in-bounds
function inBounds(lng, lat, bounds) {
  const eastBound = lng < bounds.NE[0];
  const westBound = lng > bounds.SW[0];
  let inLong;

  if (bounds.NE[0] < bounds.SW[0]) {
    inLong = eastBound || westBound;
  } else {
    inLong = eastBound && westBound;
  }

  const inLat = lat > bounds.SW[1] && lat < bounds.NE[1];
  return inLat && inLong;
}

function getGeolocation() {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by the browser.');
      reject('notSupported');
    } else {
      console.log('Geolocation is supported');
      navigator.geolocation.getCurrentPosition((pos) => {
        console.log('pos', pos);
        resolve({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude
        });
      }, (error) => {
        if (error.code === 1) {
          console.log('Geolocation permissions denied');
          reject('permissionDenied');
        } else {
          console.log(`ERROR - Geolocation: ${error}`);
          reject('unableToRetrieveLocation');
        }
      }, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      });
    }
  });
}

const MapBox = ReactMapboxGl.Map({
  accessToken: process.env.GATSBY_MAPBOX_TOKEN,
  scrollZoom: true, // disable scroll zoom
  interactive: true,
});

const mapStyle = {
  morning: "mapbox://styles/reallondon/cjztqzocb08b11ck1p6l8agqv?optimize=true",
  afternoon: "mapbox://styles/reallondon/cjztqzocb08b11ck1p6l8agqv?optimize=true",
  evening: "mapbox://styles/reallondon/cjzto08py07rs1cqgmg7j9za6?optimize=true"
}

const MENU_BAR_WIDTH_SMALL = 42;
const MENU_BAR_WIDTH_LARGE = 64;
const LOCATION_CONTENT_MAX_WIDTH = 420;

const fitBoundsOptions = {
  padding: 40,
  offset: [0, 0],
  maxZoom: 22,
  linear: true
};

function MapContainer({ locations, initialLocation, videoEmbed }) {
  const { state: appState } = useContext(Store);
  const {locateError, unableToRetrieveLocation, locationPermissionsDenied, webGLNotSupported} = useTranslations();
  const mapRef = useRef();
  const initialBounds = useMemo(() => getBounds(locations), [locations]);
  const initialState = {
    mapLoaded: false,
    places: locations.reduce((acc, value) => {
      acc[value.id] = value;
      return acc;
    }, {}),
    allPlaceIds: locations.map((location => location.id)),
    activePlaceId: 'all',
    bounds: [initialBounds.SW, initialBounds.NE],
    userLocation: null,
    locateLoading: false,
    locateOutOfBounds: false,
    showLocateMessage: false,
    locateMessage: null,
    initalLocationLoaded: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    mapLoaded,
    places,
    allPlaceIds,
    activePlaceId,
    bounds,
    userLocation,
    locateLoading,
    showLocateMessage,
    locateMessage,
    initalLocationLoaded } = state;
  const sweetSpot = window.innerHeight / 2;

   // create refs for locations to get position
  const refs = useRef(Object.keys(places).reduce((acc, value) => {
    acc[value] = createRef();
    return acc;
  }, {}));

  const scrollPosition = useWindowScroll();
  const windowSize = useWindowSize();

  const goToLocation = useCallback((lng, lat) => {
    const currentZoom = appState.isMobile ? 14 : mapRef.current.getZoom();

    mapRef.current.easeTo({
      center: [lng, lat],
      zoom: currentZoom,
      offset:[0, 0],
    });
  }, [appState.isMobile]);

  // update places
  useEffect(() => {
    const locationsDict = locations.reduce((acc, value) => {
      acc[value.id] = value;
      return acc;
    }, {});
    
    const locationIds = locations.map(location => location.id);
    const locationsBounds = getBounds(locations);

    refs.current = Object.keys(locationsDict).reduce((acc, value) => {
      acc[value] = createRef();
      return acc;
    }, {});

    dispatch(updatePlaces(locationsDict, locationIds, [locationsBounds.SW, locationsBounds.NE]));
  }, [locations]);

  // manage active place
  useEffect(() => {
    function setActiveLocation(id) {
      if (id === activePlaceId) return;

      if (id === 'all') {
        mapRef.current.fitBounds(bounds, fitBoundsOptions);

      } else {
        goToLocation(places[id].longitude, places[id].latitude);

        refs.current[id].current.classList.add('active');
      }
      
      // remove active class from previous active place.
      if (activePlaceId !== 'all') {
        refs.current[activePlaceId].current.classList.remove('active');
      }

      dispatch(setActivePlace(id));
    }

    function isElementOnScreen(id) {
      var element = refs.current[id].current;
      if (!element) return;
      
      var bounds = element.getBoundingClientRect();
      return bounds.top < sweetSpot && bounds.bottom > sweetSpot;
    }

    if (mapLoaded) {
      for (let i = 0, len = allPlaceIds.length; i < len; i++) {
        let placeId = parseInt(allPlaceIds[i]);
        if (isElementOnScreen(placeId)) {
          setActiveLocation(placeId);
          break;
        }
      }
    }
  }, [
    scrollPosition,
    places,
    activePlaceId,
    mapLoaded,
    allPlaceIds,
    bounds,
    sweetSpot,
    windowSize.width,
    initialLocation,
    goToLocation
  ]);

  // set initial location if initialLocation
  useEffect(() => {
    if (mapLoaded && initialLocation && !initalLocationLoaded) {
      const identifyingSlug = initialLocation.substring(1);
      const initial = Object.keys(places).find(el => {
        return places[el].slug === identifyingSlug;
      });

      if (initial) {
        const element = refs.current[parseInt(initial)].current;
        const elementBounds = element.getBoundingClientRect();

        setTimeout(() => {
          window.scroll(0, elementBounds.top + scrollPosition.y);

          dispatch(setInitialLocationLoaded(true));
        }, 100);
      } else {
        console.log(`'${identifyingSlug}' location does not exist`);
      }
    }
  }, [mapLoaded, initialLocation, places, scrollPosition.y, initalLocationLoaded]);

  // on resize map.
  useEffect(() => {
    if (mapRef.current && !appState.isMobile) {
      mapRef.current.fitBounds(bounds, fitBoundsOptions);
    }
    
  }, [windowSize.width, windowSize.height, bounds, appState.isMobile]);


  function handleStyleLoad(map) {
    // set map to mapRef
    mapRef.current = map;

    dispatch(setMapLoaded(true));
    console.log('Map Loaded!');
  }

  function handleMarkerClick(marker) {
    const markerId = marker.currentTarget.children[0].id;
    const element = refs.current[markerId].current;
    const elementBounds = element.getBoundingClientRect();

    setTimeout(() => {
      window.scroll(0, elementBounds.top + scrollPosition.y);
    }, 100);
  }

  function handleFindMeClick() {
    dispatch(setLocateLoading(true));

    const myPosition = getGeolocation();

    myPosition.then(res => {
      const withinBounds = inBounds(res.lng, res.lat, initialBounds);

      if (withinBounds) {
        dispatch(setUserLocation(res.lng, res.lat));
        goToLocation(res.lng, res.lat);
      } else {
        // show message 'You are not near any locations';
        dispatch(setShowLocateMessage(true, locateError));
      }

      dispatch(setLocateLoading(false));
    }).catch(err => {
      let message = '';

      switch(err) {
        case 'permissionDenied':
          message = locationPermissionsDenied;
          break;
        default:
          message = unableToRetrieveLocation;
          break;
      }

      dispatch(setShowLocateMessage(true, message));
      dispatch(setLocateLoading(false));
    });
  }

  function hideLocateMessage() {
    dispatch(setShowLocateMessage(false));
  }

  function handleZoomIn() {
    mapRef.current.zoomIn();
  }

  function handleZoomOut() {
    mapRef.current.zoomOut();
  }

  const mapContainerClasses = classNames('map-container', {
    'map-container--map-loaded': mapLoaded
  });

  return (
    <>
      <div className={mapContainerClasses}>
       
          <div className="map-loading">
            <div className="map-loading__icon" style={{
              backgroundImage: `url(${pentagonIcon})`
            }} />
          </div>
       
        {isMapboxSupported && 
          <MapBox
            style={mapStyle[appState.timeOfDay]}
            containerStyle={{
              width: '100%',
              height: '100vh'
            }}
            fitBounds={bounds}
            fitBoundsOptions={fitBoundsOptions}
            className="map"
            onStyleLoad={handleStyleLoad}
          >
            {/* add markers */}
            {allPlaceIds.map((id, index) => (
              <Marker
                key={id}
                coordinates={[places[id].longitude, places[id].latitude]}
                anchor="bottom"
                onClick={handleMarkerClick}
                className={`marker-wrapper ${id === activePlaceId ? 'marker-wrapper--active' : ''}`}
              >
                <MapMarker
                  id={id}
                  index={index}
                  type={places[id].type}
                  active={id === activePlaceId}
                  category={places[id].type === 'hotel' ? 'hotel' : places[id].category.length > 0 ? places[id].category[0].slug : ''}
                />
              </Marker>
            ))}

            {userLocation !== null && 
              <Marker
                coordinates={[userLocation[0], userLocation[1]]}
                anchor="bottom"
              >
                <div className="user-location-marker"></div>
              </Marker>
            }
          </MapBox>
        }

        {!isMapboxSupported && 
          <div className="notifcation-container">
            <div className="notification-contents">
              {webGLNotSupported}
            </div>
          </div>
        }
        
        {isMapboxSupported &&
          <div className="map-controls">
            <button type="button" className="btn map-zoom-control map-zoom-control--in" onClick={handleZoomIn} />
            <button type="button" className="btn map-zoom-control map-zoom-control--out" onClick={handleZoomOut} />

            <LocateButton
              onClick={handleFindMeClick}
              loading={locateLoading}
              showMessage={showLocateMessage}
              message={locateMessage}
              onCloseClick={hideLocateMessage}
            />
          </div>
        }
      </div>

      <div className="map-features">
        <div className="features">
          {/* add locations */}
          {allPlaceIds.map((id, index) => (
            <Location
              key={id}
              forwardedRef={refs.current[id]}
              {...places[id]}
            />
          ))}
        </div>
      </div>
      
      {videoEmbed && !isIE &&
        <div className="video-footer">
          <div className="video-footer-content">
            <div className="video-container" dangerouslySetInnerHTML={{ __html: videoEmbed }} />
          </div>
        </div>
      }
    </>
  );
}


MapContainer.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string.isRequired,
    // hours: PropTypes.number,
    id: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }))
};

export default MapContainer;



/*
mobile
- no map interactivity
- center icon
- zoom level 14 (medium)
- scroll transiton to icon at fixed zoom level

desktop
- map interactivity
- on resize fit all locations in bounds
- markers add transparency to non active markers
- scroll transiton get current zoom level
- easeTo location on markerClick
*/