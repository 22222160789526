import React from 'react';
import classNames from 'classnames';
import './close-button.scss';

function CloseButton({ onClick, className }) {

  const closeButtonClasses = classNames(`close-button ${className}`);

  return (
    <button type="button" className={closeButtonClasses} onClick={onClick} />
  );
}

export default CloseButton;