import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './location.scss';
import classNames from 'classnames';
import useTranslations from '../../hooks/useTranslations';
import ShowMoreButton from '../ShowMoreButton';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import ResponsiveImage from '../ResponsiveImage';
import Share from '../Share';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import BackgroundImage from '../BackgroundImage';
import CloseButton from '../CloseButton';
import 'objectFitPolyfill';
// check if window exists for ssr
const Slider = typeof window !== 'undefined' && require('../Slider').default;

function Location({
  id,
  address,
  title,
  slug,
  latitude,
  longitude,
  forwardedRef,
  mainImage,
  hours,
  timeOfDay,
  category,
  googleMapsLink,
  phone,
  websiteLink,
  excerpt,
  body,
  gallery,
  contributor,
  type
}) {
  const [expanded, setExpanded] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const flktyRef = useRef();
  const locationClasses = classNames('location', {
    'location--expanded': expanded
  });
  const { recommendedBy, visitWebsite } = useTranslations();

  function handleExpand() {
    if (!expanded) {
      trackExpand();
    }

    setExpanded(expanded ? false : true);
  }

  function trackExpand() {
    const cat = typeof category !== 'undefined' ? category[0].title : 'Hotel';

    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'click', {
        'event_category': 'location_expand',
        'event_label': title,
      });

      window.gtag('event', 'click', {
        'event_category': 'location_expand_category',
        'event_label': cat,
      });
    }
  }

  function handleDotClick(e) {
    const index = parseInt(e.currentTarget.dataset.index);
    flktyRef.current.flkty.select(index);
  }

  useEffect(() => {
    if (flktyRef.current) {
      // update currentImage on image change.
      flktyRef.current.flkty.on('change', () => {
        setCurrentImage(flktyRef.current.flkty.selectedIndex);
      });
    }
  }, []);

  useEffect(() => {
    // trigger checking lazyload elements in viewport
    forceCheck();

    if (expanded) {
      // resize carousel
      if (flktyRef.current) {
        setTimeout(() => {
          flktyRef.current.flkty.resize();
          // flktyRef.current.flkty.select(0);
          // setCurrentImage(1);
        }, 0);
      }
    }
  }, [expanded]);

  return (
    <div ref={forwardedRef} className={locationClasses} id={id}>
      <div className="location-images">
        {gallery.length > 0 &&
          <div className="location-gallery">
            {/* <div className="location-gallery__background" /> */}
            <div className="location-gallery__container">
              <CloseButton onClick={handleExpand}/>
              <div className="dots">
                {gallery.map((dot, index) => (
                  <div
                    key={index}
                    className={`dot ${currentImage === index ? 'dot--active' : '' }`}
                    onClick={handleDotClick}
                    data-index={index}
                  />
                ))}
              </div>
              {Slider && 
                <Slider
                  ref={flktyRef}
                  className={'slider'}
                  options={{
                    prevNextButtons: false,
                    pageDots: false,
                    setGallerySize: false,
                    contain: true,
                    cellAlign: 'left',
                    draggable: true,
                  }}
                >
                  {flktyRef.current && 
                    gallery.map(image => {

                      return (
                        <LazyLoad once key={image.id}>
                          <ResponsiveImage
                            // key={image.id}
                            width={image.width}
                            height={image.height}
                            src={image.l}
                            sources={{ ...image }}
                            sizes={`(min-width: 768px) 75vw, 100vw`}
                          />
                        </LazyLoad>
                      );
                    })
                  }
                </Slider>
              }
            </div>
          </div>
        }

        <div className="location-card-content-wrapper location-thumb clearfix" onClick={handleExpand}>
          {mainImage.length > 0 &&
            <div className="image-container ratio-1:1">
              <LazyLoad throttle={200} offset={500} once>
                <BackgroundImage src={mainImage[0].l} />
              </LazyLoad>
            </div>
          }
        </div>
      </div>

      <div className="location-card">
        <div className="location-card-content-wrapper">
          <div className="location-card__content">
            {category &&
              <h4 className="text-xs mb-1 md:mb-2 opacity-75">{category[0].title}</h4>
            }

            <h2 className="location-card__title font-bold leading-tight mb-2 md:mb-4 break-words">{title}</h2>

            {excerpt && <blockquote className="text-base md:text-xl font-bold mb-2 md:mb-6">{excerpt}</blockquote>}

            <address className="location__contact-info">
              {googleMapsLink
                ? <span className="text-xs block mb-2 uppercase break-words"><OutboundLink href={googleMapsLink} target="_blank" rel="noopener noreferrer">{address}</OutboundLink></span>
                : <span className="text-xs block mb-2 uppercase break-words">{address}</span>
              }
              {websiteLink &&
                <span className="text-xs block mb-2">
                  <OutboundLink href={websiteLink} target="_blank" rel="noopener noreferrer" className="text-xs mb-2 break-words">{visitWebsite}</OutboundLink>
                </span>
              }
              {phone && 
                <span className="text-xs block mb-2">
                  <OutboundLink href={`tel:${phone}`} className="text-xs mb-2 break-words">{phone}</OutboundLink>
                </span>
              }
            </address>
            
            <ShowMoreButton
              className="mt-4 md:mt-6"
              onClick={handleExpand}
              active={expanded}
            />
          </div>
        </div>
      </div>
      
      <div className="location-content">
      
        <div className="location-card-content-wrapper">
          <div className="location-content__inner">
            {body && 
              <p className="location__body-text text-sm md:text-base" dangerouslySetInnerHTML={{__html: body.content }}></p>
            }

            {typeof contributor !== 'undefined' && contributor.length > 0 &&
              <div className="location-contributor">

                { type !== 'hotel' && 
                  <span className="text-xs md:text-sm opacity-75 w-full block mb-2">{recommendedBy}:</span>
                }

                <div className="loctaion-contributor__info">
                  <h3 className="text-2xl md:text-3xl font-bold mb-2">{contributor[0].title}</h3>
                  <h3 className="text-xs md:text-sm uppercase mb-2 opacity-75">{contributor[0].occupation}</h3>
                  <OutboundLink className="link text-xs md:text-sm block" href={contributor[0].websiteLink} target="_blank" rel="noopener noreferrer">{contributor[0].website}</OutboundLink>
                </div>
              
                {contributor[0].mainImage.length > 0 &&
                  <div className="location-contributor__image">
                    <div className="image-container ratio-1:1">
                      <LazyLoad throttle={200} offset={500} once>
                        <BackgroundImage src={contributor[0].mainImage[0].m} />
                      </LazyLoad>
                    </div>
                  </div>
                }
              </div>
            }

            <Share
              url={typeof window !== 'undefined' ? `${window.location.href}` : ''}
              title={title}
              hash={slug}
            />

            <ShowMoreButton
              className="mt-4 md:mt-6"
              onClick={handleExpand}
              active={expanded}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

Location.propTypes = {
  id: PropTypes.number.isRequired,
  address: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

// export default React.forwardRef(Location);
export default Location;