import React, { useReducer, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import MapContainer from '../../components/MapContainer';
import './city.scss';
import { Store } from '../../Store';
import classNames from 'classnames';
import { setHours } from '../../actions/appActions';
import SEO from '../../components/seo';

function combineLocations(locations, hotels) {
  const l = locations.map(location => ({...location, type: 'location'}));
  const h = hotels.map(hotel => ({...hotel, type: 'hotel'}));
  return [...l, ...h];
}

const SET_LOCATIONS = 'SET_LOCATIONS';

function setLocations(locations) {
  return {
    type: SET_LOCATIONS,
    locations: locations
  }
}

const initalState = {
  locations: [],
  showMap: false,
};

function reducer(state, action) {
  // console.log(action);
  switch (action.type) {
    case SET_LOCATIONS:
      return {
        ...state,
        locations: action.locations
      }
    default:
      return state;
  }
}

const hoursDict = {
  one: 1,
  three: 3,
  six: 6,
  twentyfour: 24
};

function City(props) {
  const {
    hotels,
    locations,
    title,
    videoEmbed
  } = props.data.craft.city;
  const { hash } = props.location;
  const { state: appState, dispatch: appDispatch } = useContext(Store);
  const [state, dispatch] = useReducer(reducer, initalState);
  const { seoTitle, seoDescription, seoImage, seoKeywords } = props.data.craft.globals.globalSeo;
  const language = props.pageContext.locale;

  // update state if there's a hash
  useEffect(() => {
    if (hash) {
      // set hours to 24
      appDispatch(setHours(24));
    }
  }, [hash, appDispatch]);

  // update locations on filter change
  useEffect(() => {
    function filterLocations(locations) {
      /**
       * if selected hours = 24 show all locations and hide timeOfDay
       * if locations hours are less than selected hours show location
       * if timeOfDay === to selected timeOfDay display location
       */
      return locations.filter(location => {
        const withinHours = location.hours.some(hour => hoursDict[hour] <= appState.hours);
        const inTimeOfDay = location.timeOfDay.some(tod => tod === appState.timeOfDay);

        return withinHours && inTimeOfDay;
      });
    }

    let filteredLocations = [];
    if (appState.hours !== 24) {
      filteredLocations = filterLocations(locations);
    } else {
      filteredLocations = locations;
    }

    const combinedLocations = combineLocations(filteredLocations, hotels);

    dispatch(setLocations(combinedLocations));
  }, [locations, appState.hours, hotels, appState.timeOfDay]);


  const cityClasses = classNames({
    city: true
  });

  return (
    <Layout location={props.location}>
      <SEO
        lang={language}
        title={`${title} | ${seoTitle}`}
        description={seoDescription}
        keywords={seoKeywords ? seoKeywords.split(',').map(item => item.trim()) : ''}
        image={seoImage[0].url || ''}
      />
      <div className={cityClasses}>
        {state.locations.length > 0 && 
          <MapContainer
            locations={state.locations}
            initialLocation={hash}
            videoEmbed={videoEmbed}
          />
        }
      </div>
    </Layout>
  );
}

export default City;

export const query = graphql`
  query CityQuery($locale: String!, $id: [Int]) {
    craft {
      city: entry(section:[cities], id: $id, site: $locale) {
        ...on Craft_Cities {
          id
          title
          videoEmbed
          hotels {
            ...on Craft_Hotels {
              id
              slug
              title
              mainImage {
                id
                title
                width
                height
                url
                xs: url(transform:xs)
                s: url(transform:s)
                m: url(transform:m)
                l: url(transform:l)
                xl: url(transform:xl)
              }
              address
              latitude
              longitude
              googleMapsLink
              phone
              websiteLink
              excerpt
              body {
                content
              }
              gallery {
                id
                url
                xs: url(transform:xs)
                s: url(transform:s)
                m: url(transform:m)
                l: url(transform:l)
                xl: url(transform:xl)
              }
              contributor {
                ...on Craft_Contributors {
                  id
                  title
                  slug
                  mainImage {
                    id
                    title
                    width
                    height
                    url
                    xs: url(transform:xs)
                    s: url(transform:s)
                    m: url(transform:m)
                    l: url(transform:l)
                    xl: url(transform:xl)
                  }
                  occupation
                  website
                  websiteLink
                }
              }
            }
          }
          locations {
            ...on Craft_Locations {
              id
              slug
              title
              mainImage {
                id
                title
                width
                height
                url
                xs: url(transform:xs)
                s: url(transform:s)
                m: url(transform:m)
                l: url(transform:l)
                xl: url(transform:xl)
              }
              hours
              timeOfDay
              category {
                id
                title
                slug
              }
              address
              latitude
              longitude
              googleMapsLink
              phone
              websiteLink
              excerpt
              body {
                content
              }
              gallery {
                id
                title
                width
                height
                url
                xs: url(transform:xs)
                s: url(transform:s)
                m: url(transform:m)
                l: url(transform:l)
                xl: url(transform:xl)
              }
              contributor {
                ...on Craft_Contributors {
                  id
                  title
                  slug
                  mainImage {
                    id
                    title
                    width
                    height
                    url
                    xs: url(transform:xs)
                    s: url(transform:s)
                    m: url(transform:m)
                    l: url(transform:l)
                    xl: url(transform:xl)
                  }
                  occupation
                  website
                  websiteLink
                }
              }
            }
          }
        }
      }
      globals(site: $locale) {
        globalSeo {
          seoTitle
          seoDescription
          seoKeywords
          seoImage {
            id
            url
          }
        }
      }
    }
  }
`;