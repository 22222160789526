import React from 'react';
import './share.scss';
import useTranslations from '../../hooks/useTranslations';

function Share({ url = window.location.href, title = '', hash }) {
  const { share, tweet } = useTranslations();
  const popupWidth = 680;
  const popupHeight = 440;

  function handleShareClick(e) {
    const type = e.currentTarget.dataset.shareType;
    const target = targetUrl(type);
    console.log('target', target);
    popupHandler(e, target);
    trackShare();
  }

  function trackShare() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'click', {
        'event_category': 'share',
        'event_label': title,
      })
    }
  }

  function targetUrl(shareType) {
    let t;

    switch(shareType) {
      case 'facebook':
        t = `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(url)}${encodeURIComponent(`#${hash}`)}`;
        break;
      case 'twitter':
        t = `https://twitter.com/intent/tweet?url=${encodeURI(url)}${encodeURIComponent(`#${hash}`)}&text=${encodeURI(title)}&hashtags=Navigator`;
        break;
      default:
        return null;
    }

    return t;
  }

  function popupHandler(e, targetUrl) {
    // popup position
    const px = Math.floor(((window.screen.availWidth || 1024) - popupWidth) / 2),
          py = Math.floor(((window.screen.availHeight || 700) - popupHeight) / 2);

    // open popup
    const popup = window.open(targetUrl, "social", 
      `width=${popupWidth},
      height=${popupHeight},
      left=${px},
      top=${py},
      location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1`);

    console.log('popup', popup);
    if (popup) {
      popup.focus();
      if (e.preventDefault) e.preventDefault();
      e.returnValue = false;
    }

    return !!popup;
  }

  return (
    <div className="share">
      <a href="javscript:;" className="share__link" data-share-type="facebook" onClick={handleShareClick}>
        <span className="share__icon">
          <svg className="article-share-facebook" data-testid="social-icon-Facebook" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path d="M10.424 16V9.96h2.014l.31-2.35h-2.324V6.113c0-.379.082-.663.245-.852.164-.19.47-.284.917-.284h1.239V2.886c-.276-.069-.878-.103-1.807-.103-.93 0-1.665.27-2.208.813-.542.542-.813 1.303-.813 2.284v1.73h-2.04v2.35h2.04V16H0V0h16v16h-5.576z"></path>
          </svg>
        </span>
        <span className="share__text">{share}</span>
      </a>

      <a href="javscript:;" className="share__link" data-share-type="twitter" onClick={handleShareClick}>
        <span className="share__icon">
          <svg className="article-share-twitter" data-testid="social-icon-Twitter" xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
            <path d="M16.172 3.5v.438c0 1.166-.229 2.347-.686 3.542a10.017 10.017 0 0 1-2.021 3.239c-.89.964-1.99 1.753-3.296 2.364-1.307.611-2.81.917-4.509.917-.515 0-1.025-.034-1.53-.101a10.435 10.435 0 0 1-2.847-.8A9.927 9.927 0 0 1 0 12.42a10.295 10.295 0 0 0 .879.033c.855 0 1.679-.13 2.47-.387a6.942 6.942 0 0 0 2.135-1.127c-.808 0-1.529-.233-2.162-.699a3.63 3.63 0 0 1-1.318-1.758c.117.033.231.053.343.059.111.005.231.008.36.008a4.741 4.741 0 0 0 .984-.1 3.662 3.662 0 0 1-2.109-1.23 3.323 3.323 0 0 1-.844-2.237v-.068c.246.135.507.242.783.32.275.079.565.118.87.118a3.491 3.491 0 0 1-1.196-1.237 3.295 3.295 0 0 1-.457-1.69c0-.315.047-.624.14-.926.095-.303.224-.59.388-.859a9.61 9.61 0 0 0 1.511 1.464 10.647 10.647 0 0 0 3.859 1.902c.72.19 1.462.303 2.223.336a3.275 3.275 0 0 1-.105-.807c0-.483.097-.94.29-1.372.193-.432.457-.807.791-1.127A3.759 3.759 0 0 1 12.445 0c.54 0 1.04.101 1.503.303.463.202.864.471 1.204.808a8.246 8.246 0 0 0 1.23-.329c.4-.14.774-.31 1.126-.513-.14.404-.352.774-.633 1.11a3.85 3.85 0 0 1-.984.842c.363-.045.72-.112 1.072-.202.351-.09.697-.213 1.037-.37A8.305 8.305 0 0 1 16.172 3.5z"></path>
          </svg>
        </span>
        <span className="share__text">{tweet}</span>
      </a>
    </div>
  );
}

export default Share;